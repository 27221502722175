<template>
  <div class="page-template-library">
    <h2 class="p-title">{{headerTitle}}</h2>
    <yos-tab-nav :list="tabNav" />
    <bread-crumbs :list="breadList" />
    <div class="p-card">
      <div class="card-title">模板信息</div>
      <div class="left-fixed-item fix">
        <span class="left-fixed">编号</span>
        <div class="right-wrapper">
          <yos-input
            v-model.trim="id"
            placeholder="请填写模板库编号(必填)"
            :readOnly="this.$route.params.id"
          />
          <p>公众号模板库中的模板编号</p>
        </div>
      </div>
      <div class="left-fixed-item fix">
        <span class="left-fixed">标题</span>
        <div class="right-wrapper">
          <yos-input
            v-model.trim="title"
            placeholder="请填写标题(必填)"
          />
          <p>最多20个字。</p>
        </div>
      </div>
      <div class="left-fixed-item fix">
        <span class="left-fixed">行业</span>
        <div class="right-wrapper">
          IT科技 - 互联网|电子商务
        </div>
      </div>
      <div class="left-fixed-item fix">
        <span class="left-fixed">备注</span>
        <div class="right-wrapper">
          <textarea v-model.trim="remark" placeholder="请填写模板备注"></textarea>
          <p>最多500个字。</p>
        </div>
      </div>
      <bottom-options @save="onSave" />
    </div>
  </div>
</template>

<script>
import YosTabNav from '../../components/other/tab-nav'
import BreadCrumbs from '@/components/common/bread-crumbs'
import BottomOptions from '@/components/common/bottom-options'
import { ref, reactive, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useServer, useTabNav, useToast } from '../../hook'
import {
  getTemplateLibrary,
  addTemplateLibrary,
  updateTemplateLibrary
} from '../../data'
import { getPageNavs } from '../../util'

export default {
  components: {
    YosTabNav,
    BreadCrumbs,
    BottomOptions
  },
  setup() {
    const toast = useToast()
    const router = useRouter()
    const route = useRoute()
    const { id } = route.params
    const headerTitle = ref('创建模板库模板')
    const nav = getPageNavs('fun')
    nav[2].active = true
    const breadList = ref([{
      name: '模板库',
      to: '/fun/template_libraries'
    }])
    const state = reactive({
      id,
      title: '',
      remark: ''
    })

    const success = ({ msg }) => {
      toast(msg)
      router.back()
    }

    const setTemplateLibraryParams = useServer(getTemplateLibrary, ({id, title, remark}) => {
      state.id = id
      state.title = title
      state.remark = remark
    })

    const setAddTemplateLibraryParams = useServer(addTemplateLibrary, success)
    const setUpdateTemplateLibraryParams = useServer(updateTemplateLibrary, success)

    const onSave = async () => {
      const params = { id: state.id, title: state.title, remark: state.remark }

      if (id) {
        setUpdateTemplateLibraryParams(params)
      } else {
        setAddTemplateLibraryParams(params)
      }
    }

    if (id) {
      setTemplateLibraryParams({ id })
      headerTitle.value = '编辑模板库模板'
    }

    breadList.value.push({ name: headerTitle.value })

    return {
      tabNav: useTabNav(nav),
      ...toRefs(state),
      headerTitle,
      breadList,
      onSave
    }
  }
}
</script>

<style lang="postcss">
.page-template-library {
  textarea {
    border: 1px solid #ccc;
    padding: 5px 10px;
    font-size: 13px;
    border-radius: 3px;
    width: 400px;
    line-height: 20px;

    &::-ms-clear, &::-ms-reveal {
      display: none;
    }

    &:focus {
      border-color: #1aad19;
    }

    &:read-only, &:disabled {
      background-color: #e5e5e5;

      &:focus {
        border-color: #ccc;
      }
    }
  }
}
</style>
